<template>
    <div class="create-new-sale">
        <div v-loading="is_panding" class="create-new-sale-wraper">
            <div class="create-new-sale-wraper-title">
                <h2>הוספת מבצע חדש</h2>
                <div @click="$emit('close')" class="exit-btn">
                  <i class="material-icons">close</i>
                </div>
            </div>
            <div class="create-new-sale-wraper-content">
                <div class="create-new-sale-wraper-content-fill">
                    <h3>שם מבצע:</h3>
                    <el-input v-model="sale_data.name" placeholder="שם מבצע" />
                </div>
                <div class="create-new-sale-wraper-content-fill">
                    <h3> ברקוד מוצר:</h3>
                    <el-input v-model="sale_data.barcode" placeholder="ברקוד" />
                </div>
                <div class="create-new-sale-wraper-content-fill">
                    <h3>תיאור המבצע</h3>
                    <el-input
                        style="white-space: pre-wrap;"
                        v-model="sale_data.description"
                        :rows="4"
                        type="textarea"
                        placeholder="תיאור המבצע..."
                    />
                </div>
                <div class="create-new-sale-wraper-content-fill">
                    <h3> מחיר ליחידה:</h3>
                    <el-input type="number" :min="0" :step="0.1" v-model="sale_data.unit_price" placeholder="מחיר ליחידה" />
                </div>
                <div class="create-new-sale-wraper-content-fill">
                    <h3>כמות במארז:</h3>
                    <el-input type="number" v-model="sale_data.pac_amount" placeholder="כמות" />
                </div>
                <div class="create-new-sale-wraper-content-fill">
                    <label for="is_deal">
                        <input v-model="sale_data.is_deal" id="is_deal" type="checkbox">
                        מבצע בהנחה?
                    </label>
                </div>
                <template v-for="(deal,index) in sale_data.deal_datails" :key="index">
                    <div v-if="sale_data.is_deal" class="discount-details">
                        <label style="width: 30%;" for="">
                            מכמות מארזים
                            <el-input  type="number" :min="0"  v-model="deal.amount" placeholder="כמות" />
                        </label>
                        
                        <label style="width: 30%;" for="">
                            מחיר ליחידה
                            <el-input  type="number" :min="0"  v-model="deal.discount_price" placeholder="מחיר ליחידה" />
                        </label>
                        <div class="remove">
                            <i @click="handle_remove_deal(index)" style="cursor: pointer; user-select: none; color: red;" class="material-icons">remove</i>
                        </div>
                        <div class="add">
                            <i @click="handle_add_deal" v-if="index==sale_data.deal_datails.length-1" style="cursor: pointer; user-select: none; color: green;" class="material-icons">add</i>
                        </div>
                    </div>
                </template>
                <div class="create-new-sale-wraper-content-fill">
                    <h3>תמונה</h3>
                    <div v-loading="image_panding" class="image">
                        <img v-if="!sale_data.image" @click="input_file_ref.click()" :src="upload_icon" alt="תמונת ברירת מחדל">
                        <img v-if="sale_data.image" :src="sale_data.image" alt="תמונה מבצע">
                        <div @click="handle_delete_image" v-if="sale_data.image" class="exit-btn">
                            <i class="material-icons">delete</i>
                        </div>
                    </div>
                </div>
            </div>
            <div class="create-new-sale-wraper-footer">
                    <el-button v-if="!selected_edit" @click="handle_submit" style="width: 85%;" type="success">הוספה</el-button>
                    <el-button v-if="selected_edit" @click="handle_update" style="width: 85%;" type="warning">עדכון</el-button>
            </div>
        </div>
        <input ref="input_file_ref" v-show="false" type="file" @change="handle_change">
    </div>
</template>

<script>
    import upload_icon from '../../assets/images/upload_icon.png'
    import {slide_pop_error,alert_confirm,alert} from '../../Methods/Msgs'
    import upload_image_storage from '../../Methods/use_general_storage'
    import {add_sale_to_db,edit_sale_to_db} from './scripts'
    import {ref} from 'vue'
    export default {
        emits:['close','submit','edit_submit'],
        props:['selected_edit'],
        setup(props,{emit}){

            const {url,uploadImage, delete_image_by_url} = upload_image_storage()
            const input_file_ref = ref(null)
            const image_panding = ref(false)
            const is_panding = ref(false)

            const sale_data = ref({
                created_at: new Date(),
                name:'',
                barcode:'',
                description:'',
                unit_price:0,
                pac_amount:0,
                image:null,
                is_deal:false,
                deal_datails:[
                    {
                        amount:0,
                        discount_price:0
                    },
                ],
                uid:''
            })

            const handle_change = async(e) => {
                try{
             
                    const types = ['image/png','image/jpeg','image/jpg']
                    const file = e.target.files[0]
                    if(file && types.includes(file.type)){
                        if(sale_data.value.barcode && sale_data.value.name){
                            image_panding.value = true
                            await uploadImage(file,`Shlush/${sale_data.value.barcode}/${sale_data.value.name}`)
                            sale_data.value.image = url.value
                            console.log(sale_data.value);
                            image_panding.value = false
                            input_file_ref.value.value  = ''
                        }else{
                            throw new Error('עליך להזין את כל השדות לפני העלאת תמונה!')
                        }
                    }else{
                        input_file_ref.value.value  = ''
                        throw new Error('עליך להעלות תמונה בפורמט png או jpg')
                    }

                }catch(err){
                    image_panding.value = false
                    console.error(err.message);
                    slide_pop_error(err.message)
                }
            }

            const handle_delete_image = async() => {
                alert_confirm('להסיר את התמונה הנוכחית?')
                .then(async res => {
                    if(res.isConfirmed){
                        await delete_image_by_url(url.value)
                        sale_data.value.image = null
                    }
                })
            }

            const handle_remove_deal = (index) => {
                if(sale_data.value.deal_datails.length>1){
                    sale_data.value.deal_datails.splice(index,1)
                }
            }
            const handle_add_deal = () => {
                sale_data.value.deal_datails.push({
                    amount:0,
                    discount_price:0
                })
            }

            const validation = () => {
                if(!sale_data.value.name){
                    throw new Error('עליך להשין שם מבצע!')
                }
                else if(!sale_data.value.barcode){
                    throw new Error('עליך להזין ברקוד!')
                }
                else if(!sale_data.value.description){
                    throw new Error('עליך להזין תיאור!')
                }
                else if(sale_data.value.unit_price == 0){
                    throw new Error('עליך להזין מחיר ליחידה!')
                }
                else if(sale_data.value.pac_amount == 0){
                    throw new Error('עליך להזין כמות במארז!')
                }
                else if(!sale_data.value.image){
                    throw new Error('עליך להעלות תמונת פריט!')
                }

            }

            
            const handle_submit = async() => {
                try{
                    validation()
                    await add_sale_to_db(sale_data.value)
                    emit('submit',sale_data.value)
                    alert('success',`המבצע ${sale_data.value.name} הוסף בהצלחה?`)
                    .then(()=>{
                        emit('close')
                    })
                    

                }catch(err){
                    slide_pop_error(err.message)
                }
            }

            const handle_update = async() => {
                try{
                    validation()
                    await edit_sale_to_db(sale_data.value)
                    emit('edit_submit',sale_data.value)
                    alert('success',`המבצע ${sale_data.value.name} עודכן בהצלחה?`)
                    .then(() => {
                        emit('close')
                    })
                }catch(err){
                    console.error(err.message);
                    slide_pop_error(err.message)
                }
            }

            if(props.selected_edit){
                sale_data.value = props.selected_edit
            }

            return{
                upload_icon,
                sale_data,
                handle_change,
                input_file_ref,
                image_panding,
                handle_delete_image,
                handle_submit,
                is_panding,
                handle_update,
                handle_add_deal,
                handle_remove_deal
            }
        }
    }
</script>

<style scoped>
    .create-new-sale{
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0.7);
        padding: 0 5px;
    }
    .create-new-sale-wraper{
        width: 100%;
        max-width: 500px;
        height: 80%;
        background: #fff;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
    }
    .create-new-sale-wraper-title{
        position: relative;
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
    }
    .create-new-sale-wraper-content{
        width: 100%;
        flex: 1;
        padding: 0 5px;
        overflow-y: auto;
        -ms-overflow-style: none;  /* Internet Explorer and Edge */
        scrollbar-width: none; 
    }
    .create-new-sale-wraper-content::-webkit-scrollbar {
        display: none;
    }
    .create-new-sale-wraper-content-fill{
        width: 100%;
        height: auto;
        margin-bottom: 10px;
       
    }
    .discount-details{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;
    }
    .discount-details > .remove,.discount-details > .add{
        width: 15%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: end;
    }
    
    .create-new-sale-wraper-footer{
        width: 100%;
        height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .image{
        width: 100%;
        height: 200px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
    }
    .image img{
        user-select: none;
        cursor: pointer;
        max-width: 100%;
        max-height: 100%;
    }

    .exit-btn{
        position: absolute;
        width: 30px;
        height: 30px;
        top: 5px;
        left: 5px;
        z-index: 16;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-weight: 500;
        color: #fff;
        background: crimson;
        border-radius: 50%;
        cursor: pointer;
    }
</style>